import React, { useState, useContext, useEffect, useMemo } from "react";
import { getBitmap, capitalizeFirstLetter } from "../helpers";
import { EventContext } from "../context/EventContext";

const EmbroideryOption = ({option, setSelectedEmbroidery}) => {
  const [selected, setSelected] = useState(false);
  const { isMonogram } = useContext(EventContext);
  const monogram = useMemo(() => isMonogram(option.tape), [isMonogram, option.tape]);

  useEffect(() => {
    if (selected) {
      setSelectedEmbroidery(selectedEmbroidery => [...selectedEmbroidery, option])
    } else {
      setSelectedEmbroidery(selectedEmbroidery => selectedEmbroidery.filter(emb => emb !== option));
    }
  }, [selected, setSelectedEmbroidery, option]);

  if (!option?.tape || !option?.position?.name || !option?.position?.code) {
    return;
  }
  const tapeImage = getBitmap(option.tape);
  const name = capitalizeFirstLetter(option.position?.name);

  return (
    <label className="container" id="embroidery">
      <input
        checked={selected ? "checked" : ""}
        onChange={() => setSelected(selected => !selected)}
        type="checkbox"
        name="embroideryEnabled" />
      <span className="checkmark"></span>
      {!monogram ?
        <>
          <img alt="" src={tapeImage} />
          <p>{`Add Logo on ${name}`}</p>
        </>
        :
        <h3>{`Add Monogram on ${name}`}</h3>
    }
    </label>
  );
};

const Embroidery = ({
  options,
  setSelectedEmbroidery
}) => {
  if (!options) { return null; }

  return (
    <div>
      {options.map((option, index) => 
        <EmbroideryOption key={index} option={option} setSelectedEmbroidery={setSelectedEmbroidery} />
      )}
    </div>
  );
};

export default Embroidery;
