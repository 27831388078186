import React, { createContext, useContext, useState, useEffect } from "react";
import { firestore } from "../firebase";
import { setIdentity } from "../logrocket";
import { getProducts, saveUserAddress } from "../firestore";
import { EventContext } from "./EventContext";
import { checkForEmailLink } from "../auth";

export const AuthContext = createContext();

// Get the categories of all the products for this event
const getCategoriesFromProducts = (products) => {
  console.log("Getting product categories");
  if (!products) return;
  // This reduce function maps gender codes to unique categories for that gender
  const categories = products.reduce((cats, p) => {
    const gender = p.genderCode;
    const group = p.category;
    cats = {
      ...cats,
      [gender]: // Set the value of this gender key
        cats[gender] // Does this gender code already have some categories
        ? cats[gender].includes(group) // Yes, but does it already include this category
          ? [...cats[gender]] // Yes, so don't add duplicate category
          : [...cats[gender], group] // No, so add the new category
        : [group], // This gender code has no categories, add this one
    };
    return cats;
  }, {});
  return categories;
};

export const AuthProvider = ({ children }) => {
  const { productList, setProducts, setCategories, participants } = useContext(
    EventContext
  );
  const [user, setUser] = useState();
  const [hasAccess, setHasAccess] = useState();
  const [newUser, setNewUser] = useState();
  const [userData, setUserData] = useState({});

  const signOut = () => {
    setUser({uid: null});
  };

  // Set the proper states for a new user sate
  useEffect(() => {
    if (!user) return;
    if (!!user.uid) {
      // Set session storage
      firestore
        .collection("users")
        .doc(user.uid)
        .get()
        .then((snapshot) => {
          setUserData(snapshot.data());
          setSessionStorage(user, snapshot.data());
        });
    } else {
      setUserData({uid: null});
      setSessionStorage({uid: null}, {});
    }
  }, [user]);

  // Set user access from participants list
  useEffect(() => {
    if (!user) return false;
    if (!participants) {
      setHasAccess(!!user.uid);
      return;
    }
    const userEmailInParticipants = !!participants.some(p =>
      p.email && p.email.localeCompare(user.email, undefined, {sensitivity: "accent"}) === 0
    );
    setHasAccess(userEmailInParticipants);
  }, [user, participants, setHasAccess]);

  useEffect(() => {
    try {
      checkForEmailLink().then((emailUser) => {
        if (!!emailUser) {
          setUserData({email: emailUser.email});
          setUser(emailUser);
        } else {
          const sessionUser = window.sessionStorage.getItem("user");
          const sessionUserData = window.sessionStorage.getItem("userData");
          try {
            setUserData(JSON.parse(sessionUserData));
            setUser(!!sessionUser ? JSON.parse(sessionUser) : {uid: null});
          } catch (err) {
            console.log(`Error setting user data: ${err}`);
            setUser({uid: null});
          }
        }
      });
    } catch (err) {
      console.log(`Error setting user data: ${err}`);
    }
  }, []);

  // Sets the user and user data into session storage
  const setSessionStorage = (sessionUser, sessionUserData) => {
    try {
      window.sessionStorage.setItem("user", JSON.stringify(sessionUser));
      window.sessionStorage.setItem("userData", JSON.stringify(sessionUserData));
    } catch (err) {
      console.log(`Error setting user data: ${err}`);
    }
  };

  // Save the address when it changes
  useEffect(() => {
    if (!user || !user.uid || !user.address) return;
    // Don't overwrite the address if all properties are null
    if (Object.values(user.address).every(v => v === null)) { return; }
    saveUserAddress({user: user.uid, address: user.address});
  }, [user]);

  // Identify LogRocket session with user email
  useEffect(() => {
    if (!user || !user.email) return;
    setIdentity(user.email);
  }, [user]);

  // Fetch products if they haven't been fetched yet
  useEffect(() => {
    if (!!user && !!user.uid && !!productList) {
      getProducts(productList).then((result) => {
        if (!result) return;
        // Set the products for this event and get those products' categories
        const categories = getCategoriesFromProducts(Object.values(result));
        setCategories(categories);
        setProducts(result);
      });
    }
  }, [user, productList, setProducts, setCategories]);

  return (
    <AuthContext.Provider
      value={{
        signOut,
        user,
        setUser,
        userData,
        newUser,
        setNewUser,
        hasAccess,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
