import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { EventContext } from "../context/EventContext";
import { getBitmap, getPriceString, getEncodedProductStyle, getEncodedProductColor } from "../helpers";

const Product = ({ product }) => {
  const { eventID, customUnits } = useContext(EventContext);
  const productStyle = getEncodedProductStyle(product);
  const productColor = getEncodedProductColor(product);
  if (!product) return;

  const styleName = product.styleNameLong ? product.styleNameLong : product.styleCode;
  const colorName = product.colorNameLong ? product.colorNameLong : product.colorCode;

  return (
    // Go to the product detail page for this product
    <Link
      to={`/${eventID}/store/product/${productStyle}/${productColor}`}
      className="storefrontProduct"
    >
      <div
        className="productImage skeleton"
        style={{ backgroundImage: `url(${getBitmap(product.image)})` }}
      ></div>
      <div className="productDetails skeleton">
        <span className="productName">{styleName}</span>
        <span className="productShortDesc">{colorName}</span>
        <span className="productPrice">{getPriceString(product.price, customUnits)}</span>
      </div>
    </Link>
  );
};
export default Product;
